"use client";

import React, { useCallback, ComponentType, useEffect, useRef, useState  } from "react";
import { useAccount } from 'wagmi';
import { Layout, Menu, Button } from 'antd';
import type { MenuProps } from 'antd';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';
import { ConnectWallet } from "./ConnectWallet";
import type { MenuInfo } from 'rc-menu/lib/interface';
import Image from "next/image";
import useAuthUser from "@/hooks/auth/useAuthUser";
import withTheme from '../../theme/index';
import Footer from "../footer/Footer";

import './clientLayout.styles.scss';

const { Header, Content } = Layout;

const MemoizedConnectButton = React.memo(ConnectWallet);

const items: MenuProps['items'] = [
  {
    key: 'home',
    label: (
      <div className="navbar-logo">
        <Image
          src="/common/greg-logo.png"
          width={500}
          height={500}
          alt="climadao logo icon"
          className="greg-token-logo"
        />
      </div>
    ),
    className: 'homepage-logo',
  },
  {
    key: 'roadmap',
    label: 'Roadmap',
  },
  {
    key: 'ecosystem',
    label: 'Ecosystem',
    children: [
      {
        label: 'Concept',
        key: 'concept', 
      },
      {
        label: 'Projects',
        key: 'projects'
      },
      {
        label: 'Blog',
        key: 'blog'
      }
    ]
  },
  {
    key: 'about',
    label: 'About',
  },
  {
    key: 'joinDiscord',
    label: (
      <div className="join-discord-button"> 
        <Button>Join Discord</Button> 
      </div>
    ),
  },
  {
    key: 'register',
    label: (
      <div className="become-greg-button"> 
        <Button>Become Greg</Button> 
      </div>
    ),
  },
  {
    key: 'connect',
    label: (
      <div className="connect-button" onClick={(e) => e.stopPropagation()}>
        <MemoizedConnectButton />
      </div>
    ),
    className: 'homepage-connect-wallet',
  },
];

// add pages back here also
const pages: { [key in 'home' | 'roadmap' | 'register' | 'concept' | 'projects' | 'blog' | 'about']: ComponentType<{}> } = {
  home: dynamic(() => import('../../app/(home)/page')),
  roadmap: dynamic(() => import('../../app/roadmap/page'), { ssr: false }),
  concept: dynamic(() => import('../../app/concept/page'), { ssr: false }),
  projects: dynamic(() => import('../../app/projects/page'), { ssr: false }),
  blog: dynamic(() => import('../../app/blog/page'), { ssr: false }),
  about: dynamic(() => import('../../app/about/page'), { ssr: false }),
  register: dynamic(() => import('../../app/register/page'), { ssr: false }),
};

type PageKey = keyof typeof pages;

const ClientLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const router = useRouter();
  const { address, isConnected } = useAccount();
  const debouncedAddress = useAuthUser(address, 500);
  const savedAddressRef = useRef<string | null>(null);

  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsHeaderScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onClick = useCallback((e: MenuInfo) => {
    if (e.key !== 'connect') {
      router.push(e.key === 'home' ? '/' : `/${e.key}`);
    }
  }, [router]);

  useEffect(() => {
    const saveUserAddress = async () => {
      if (isConnected && debouncedAddress && savedAddressRef.current !== debouncedAddress) {
        try {
          const response = await fetch('/api/mongodb', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ address: debouncedAddress }),
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }

          console.log('User data saved');

          savedAddressRef.current = debouncedAddress;
        } catch (error) {
          console.error('Failed to save user address:', error);
        }
      }
    };

    saveUserAddress();
  }, [debouncedAddress, isConnected]);

  return withTheme(
    <Layout style={{ height: '100%' }}>
      <Header
        className={`ant-layout-header ${isHeaderScrolled ? "scrolled" : ""}`}
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'fixed', zIndex: '999' }}
      >
        <Menu
          mode="horizontal"
          defaultSelectedKeys={['home']}
          items={items.slice(0, 4)}
          style={{ flex: 1 }}
          onClick={onClick}
        />
        <Menu
          mode="horizontal"
          items={items.slice(4, 7)}
          style={{ flex: 1, justifyContent: 'flex-end' }}
          onClick={onClick}
        />
      </Header>
      <Content>
        {children}
      </Content>
      <Footer />
    </Layout>
  );
};

export default ClientLayout;
